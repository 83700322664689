<!--月报汇总页-->
<template>
  <!-- 路径 -->
  <div class="path">
    <span><router-link to="/"> 首页</router-link></span> 
    &nbsp; &gt; &nbsp;
    <span>交易月报</span>
  </div>
  <div class="card-box">
    <div class="flex-start mb_15">
      <div class="news_title1">交易月报汇总统计图</div>
      <div class="news_title2">&nbsp;</div>
    </div>
    
    <div ref="echart_ref" class="com-chart"></div>

    <div class="flex-start mb_15">
      <div class="news_title1">交易月报汇总表</div>
      <div class="news_title2">&nbsp;</div>
    </div>
    
    <div class="search-div">
      月分区间：
      <el-date-picker
        v-model="dateValue"
        type="monthrange"
        range-separator="至"
        start-placeholder="开始月份"
        end-placeholder="结束月份" 
        format="YYYY年MM月"
        value-format="YYYY-MM"             
      />
      <el-button type="primary" icon="search" @click="getSummaryData">查询</el-button>
      <el-button type="primary" icon="Download" @click="getExportData">导出</el-button>
    </div>
    <el-table v-loading="tableLoading" :data="listData" highlight-current-row stripe border
        style="width: 100%" header-row-class-name="header-row" :row-class-name="tableRowClassName">
      <!-- <el-table-column type="selection" width="40"></el-table-column>
      <el-table-column type="index" label="序" width="43"></el-table-column> -->
      <el-table-column prop="tradeMonth" label="年月" min-width="100" />
      <el-table-column prop="purchaseTotalAmount" label="采购金额" min-width="200" align="right" />
      <el-table-column prop="saleTotalAmount" label="销售金额" min-width="200" align="right" />
      <el-table-column prop="receiptTotalAmount" label="收款金额" min-width="200" align="right" />            
      <el-table-column prop="receiptTotalCount" label="收款笔数" min-width="200" />
    </el-table>
    <div class="pagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="currentPage" :page-sizes="[10, 15, 20, 30, 40]"
        :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total='tableTotal'>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {getFirstCurrentYearMonth, getCurrentYearMonth, getNextMonth, formatDate} from '@/utils/datetime_utils.js'
import {formatCurrency} from '@/utils/chineseNum.js'
export default {
  data () {
    return {
      tableLoading: false,
      currentPage: 1,
      pagesize: 20,
      tableTotal: 0,
      receiptTotalCount: 0, // 收款笔数合计
      receiptTotalAmount: 0, // 收款金额合计
      saleTotalAmount: 0, // 销售金额合计
      purchaseTotalAmount: 0, //采购金额合计
      listData: [],
      shopName: '', // 商户名称
      businessFormatName: '', // 商户经营类别
      dateValue: [],
      // chartTitle: '',
      // startDate: '', // 交易开始日期
      // endDate: '', // 交易结束日期
      // chartInstance: null,
      isFirst: true // 是否第一次访问(不是分页访问)
    }
  },
  mounted () {
    console.log('getFirstCurrentYearMonth=', this.getFirstCurrentYearMonth())
    // var dt = dateFormate('yyyy-MM')
    this.dateValue[0] = getFirstCurrentYearMonth()
    this.dateValue[1] = getCurrentYearMonth()
    this.initEchart()
    this.getSummaryData()
    window.addEventListener('resize', this.screenAdapter)
    this.screenAdapter()
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.screenAdapter)
  },
  methods: {
    getFirstCurrentYearMonth () {
      // var months = []
      var data = new Date()
      data.setMonth(data.getMonth() + 1 - 12, 1)
      const year = data.getFullYear()
      var month = data.getMonth() + 1
      month = month < 10 ? '0' + month : month
      return `${year}-${month}`
    },
    // pageSize 新每页条数改变时触发(每页下拉显示数据)
    handleSizeChange (size) {
      this.pagesize = size
      this.getListData()
    },
    // 新当前页改变时触发(点击第几页)
    handleCurrentChange (currentPage) {
      this.currentPage = currentPage
      this.getListData()
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex === 0) {
        return 'Summary-row'
      }
      return ''
    },
    getSummaries() {
      const sums = {
        tradeMonth: '合计',
        receiptTotalCount: this.receiptTotalCount,
        receiptTotalAmount: this.receiptTotalAmount,
        saleTotalAmount: this.saleTotalAmount,
        purchaseTotalAmount: this.purchaseTotalAmount
      }
      this.listData.unshift(sums)      
    },
    // 采购金额/笔数合计
    async getSummaryData () {
      var en = {
        position: 'merchantTrade_queryTradeMonthlySummary',
        paramMap: {
          orgId: '9ce5a4d757f9d6434008b1449d20a641',
          startDate: this.dateValue[0],
          endDate: getNextMonth(this.dateValue[1])
        }
      }
      const { data: res } = await this.$http.post(this.$api.querySql, en) 
      if (res.state.code === 10000) {
        // console.log('Summary', res)
        this.receiptTotalCount = formatCurrency(res.body[0].receiptTotalCount, 0) // 收款笔数合计
        this.receiptTotalAmount = '¥'+ formatCurrency(res.body[0].receiptTotalAmount, 2) // 收款金额合计
        this.saleTotalAmount = '¥'+ formatCurrency(res.body[0].saleTotalAmount, 2) // 销售金额合计
        this.purchaseTotalAmount = '¥'+ formatCurrency(res.body[0].purchaseTotalAmount, 2) //采购金额合计
        this.isFirst = true
        this.getListData()
      } else {
        this.$message.error(res.state.message)
      }
    },
    // 采购明细
    async getListData () {
      this.tableLoading = true
      var en = {
        position: 'merchantTrade_queryTradeMonthlyDetail',
        paramMap: {
          orgId: '9ce5a4d757f9d6434008b1449d20a641',
          startDate: this.dateValue[0],
          endDate: getNextMonth(this.dateValue[1])
        },
        pageable: {
            pageNumber: this.currentPage,
            pageSize: this.pagesize
        }
      }
      const { data: res } = await this.$http.post(this.$api.querySqlByPage, en) 
      // console.log('Detail', res)
      if (res.state.code === 10000) {
        this.tableTotal = res.body.totalCount
        if (this.isFirst) {
          this.isFirst = false
          this.getEchartData(JSON.parse(JSON.stringify(res.body.list)))
        }
        this.listData = res.body.list
        this.listData.forEach(p => {
          p.receiptTotalCount = formatCurrency(p.receiptTotalCount, 0) // 收款笔数
          p.receiptTotalAmount = '¥'+ formatCurrency(p.receiptTotalAmount, 2) // 收款金额
          p.saleTotalAmount = '¥'+ formatCurrency(p.saleTotalAmount, 2) // 销售金额
          p.purchaseTotalAmount = '¥'+ formatCurrency(p.purchaseTotalAmount, 2) // 采购金额
        })  
        this.getSummaries()            
      } else {
        this.$message.error(res.state.message)
      }
      this.tableLoading = false
    },
    async getExportData () {
      var dt = this.dateValue[0] === this.dateValue[1] ? formatDate(this.dateValue[0], 'yyyy-MM') : formatDate(this.dateValue[0], 'yyyy-MM') + '至' + formatDate(this.dateValue[1], 'yyyy-MM')
      var exportFileName = `交易月报汇总表${dt}`
      var en = {
        "sheetDatas":
          {
            "fields": [
              "tradeMonth",
              "purchaseTotalAmount",
              "saleTotalAmount",
              "receiptTotalAmount",
              "receiptTotalCount"              
            ],
            "titles": [
              "年月",
              "采购金额（元）",
              "销售金额（元）",
              "收款金额（元）",
              "收款笔数"
            ],
            "sheetName": '交易月报汇总表'
          },        
        "param": {
            "position": "merchantTrade_queryTradeMonthlyDetail",
            "paramMap": {
              "orgId": "9ce5a4d757f9d6434008b1449d20a641",
              "businessFormatName": this.businessFormatName,
              "shopName": this.shopName,
              "startDate": this.dateValue[0],
              "endDate": getNextMonth(this.dateValue[1])
            }
        },
        "exportFileName": exportFileName
      }
      const res = await this.$http.postdown(this.$api.queryExport, en)     
      // console.log('Export', res)
      if (res.status === 200) {
        this.$fileDownload(res.data, `${exportFileName}.xlsx`)
      } else {
        this.$message.error(res.statusText)
      }
    },
    // 图表数据
    getEchartData (list) {
      // 获取前10条数据
      var allData = list.slice(0,12) 
      // var allData = list
      var tradeMonth = allData.map(c => c.tradeMonth)
      var purchaseAmounts = allData.map(c => c.purchaseTotalAmount)
      var saleAmounts = allData.map(c => c.saleTotalAmount)
      var receiptCounts = allData.map(c => c.receiptTotalCount)
      var receiptAmounts = allData.map(c => c.receiptTotalAmount)
      // console.log('data=', allData, tradeMonth, receiptCounts, receiptAmounts)
      this.updateEchart(tradeMonth, purchaseAmounts, saleAmounts, receiptCounts, receiptAmounts)
    },
    // 初始化图表
    initEchart () {
      // this.chartInstance = markRaw(this.$echarts.init(this.$refs.chart_ref))
      this.chartInstance = this.$echarts.init(this.$refs.echart_ref)
      const initOption = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        grid: {
          // right: '20%' 
          top: 100,        
          bottom: 30
        },
        // toolbox: {
        //   feature: {
        //     dataView: { show: true, readOnly: false },
        //     magicType: { show: true, type: ['line', 'bar'] },
        //     restore: { show: true },
        //     saveAsImage: { show: true }
        //   }
        // },
        legend: {
          top: 35,
          data: ['采购金额','销售金额', '收款金额', '收款笔数' ]
        },
        xAxis: [
          {
            type: 'category',
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '金额（元）',
            // min: 0,
            // max: 25,
            // interval: 10
          },
          {
            type: 'value',
            name: '收款笔数',
            // min: 0,
            // max: 25,
            // interval: 20,
            // axisLabel: {
            //   formatter: '{value} ml'
            // }
          }
        ],
        series: [ 
          {
            name: '采购金额',
            type: 'bar',
            barGap: 0,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 元'
              }
            }
          },
          {
            name: '销售金额',
            type: 'bar',            
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 元'
              }
            }
          },
          {
            name: '收款金额',
            type: 'bar',            
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 元'
              }
            }
          },
          {
            name: '收款笔数',
            type: 'line',
            yAxisIndex: 1 
          }
        ]
      }
      this.chartInstance.setOption(initOption)      
    },
    // 更新图表
    updateEchart (tradeMonth, purchaseAmounts, saleAmounts, tradeCounts, tradeAmounts) {
      var chartTitle = this.dateValue[0] === this.dateValue[1] ? formatDate(this.dateValue[0], 'yyyy年MM月') : formatDate(this.dateValue[0], 'yyyy年MM月') + '至' + formatDate(this.dateValue[1], 'yyyy年MM月')
      const dataOption = {
        title: {
          text: chartTitle + '交易月汇总统计图'
        },
        xAxis: {
          data: tradeMonth            
        },
        series: [
          {
            data: purchaseAmounts
          },
          {
            data: saleAmounts
          },
          {
            data: tradeAmounts
          },
          {
            data: tradeCounts
          }
        ]
      }
      this.chartInstance.setOption(dataOption)
    },
    screenAdapter () {
      // 调用图表对象的resize才能产生效果
      this.chartInstance.resize()
    }
  }
}
</script>

<style>
</style>
